import React, { useEffect, useState } from 'react'

import S from './WebDeveloper.module.css'

interface WebDeveloperProps {
  title : string
}

export default function WebDeveloper( { title } : WebDeveloperProps ) {
  const [ on, setOn ] = useState( true )

  useEffect( () => {
    const timer = setInterval( () => {
      setOn( !on )
    }, 1000 )

    return () => clearInterval( timer )
  }, [ on ] )

  return (
    <a
      href="https://www.linkedin.com/in/itsmequinn"
      target="_blank"
      className={S.text}
      style={{
        color : on ? 'white' : 'rgba(255,255,255,0.3)',
        transform : on ? 'scale(1.5)' : undefined,
      }}
      rel="noreferrer"
    >
      {title}
    </a>
  )
}
