import React, { useEffect, useState } from 'react'
import { randomPastel } from './data'

import S from './ColorfulThing.module.css'

interface ColorfulThingProps {
  title : string,
  changeKey : number
}

export default function ColorfulThing( { title, changeKey } : ColorfulThingProps ) {

  const [ color, setColor ] = useState( randomPastel() )

  useEffect( () => {
    setColor( randomPastel() )
  }, [ changeKey ] )

  return (
    <span
      className={S.thing}
      style={{
        color : `rgba(${color[0]}, ${color[1]}, ${color[2]})`,
      }}
    >
      {title}

    </span>
  )
}
