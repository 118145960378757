import React, { useEffect, useState } from 'react'

import { thingsAboutMe, shuffle } from './data'

import S from './App.module.css'
import ColorfulThing from './ColorfulThing'
import WebDeveloper from './WebDeveloper'

let interval:ReturnType<typeof setInterval>
const data = shuffle( thingsAboutMe )

function App() {
  const [ changeKey, setChangeKey ] = useState( Date.now() )

  useEffect( () => {
    clearInterval( interval )
    interval = setInterval( () => {
      setChangeKey( Date.now() )
    }, 5000 )
  }, [] )

  return (
    <div className={S.app}>
      <div className={S.content}>
        <header>
          <h1 className={S.header}>Michael Quinn</h1>
        </header>
        <div className={S.thingContainer}>
          {data.map( ( datum ) => {
            if ( datum === 'Web Developer' ) {
              return (
                <WebDeveloper title={datum} />
              )
            }

            return (
              <ColorfulThing
                key={datum}
                changeKey={changeKey}
                title={datum}
              />
            )
          } )}
        </div>
      </div>
    </div>
  )
}

export default App
