export const thingsAboutMe = [
  'political junkie',
  'amateur comedian',
  'dog father',
  'occasional runner',
  'still likes The Walking Dead',
  'erstwhile New Yorker',
  'video game enthusiast',
  'brown sugar pop tart defender',
  'Simpsons stan',
  'craft beer connoisseur',
  'Apple addict',
  'Reactionary',
  'diehard Mets fan',
  'Nintendo ninja',
  'space spectator',
  'former HR Professional',
  'HTML hitman',
  'Web Developer',
  'semi-pro pizzaiolo',
]

export function shuffle( arr : Array<any> = [] ) {
  const ret = [ ...arr ]
  let currentIndex = ret.length
  let tempValue
  let randomIndex

  while ( currentIndex !== 0 ) {
    randomIndex = Math.floor( Math.random() * currentIndex )
    currentIndex -= 1

    tempValue = ret[currentIndex]
    ret[currentIndex] = ret[randomIndex]
    ret[randomIndex] = tempValue
  }

  return ret
}

export function randomPastel() {
  let red = Math.floor( Math.random() * 256 )
  let green = Math.floor( Math.random() * 256 )
  let blue = Math.floor( Math.random() * 256 )

  red = Math.round( ( red + 255 ) / 2 )
  green = Math.round( ( green + 255 ) / 2 )
  blue = Math.round( ( blue + 255 ) / 2 )

  return [ red, green, blue ]
}
